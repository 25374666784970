import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layout';
import MLink from '../components/MLink';
import './about.scss';
import TeamGallery from '../components/TeamGallery';
import AdvisorGallery from '../components/AdvisorGallery';
import SEO from '../components/SEO';

const getEmail = params => params.data && params.data.site.siteMetadata.email;

const AboutPage = props => {
  const {
    location,
  } = props;

  const title = 'About Us';
  const description = 'Learn more about us, where we came from and meet our core team';

  return (
    <Layout pathname={location.pathname} title="About us" className="about-page">
      <SEO isPost node={{ title, description }} />
      <div className="about-page-top-banner-container">
        <h1 className="about-page-top-banner-title">{title}</h1>
        <p className="about-page-top-banner-text">
          {description}
        </p>
      </div>
      <div className="about-page-content">
        <div className="">
          <h2>Our story</h2>
          <p>
            Our goal is to develop the most complete and satisfying <MLink to="https://en.wikipedia.org/wiki/MLOps">MLOps</MLink> platform.
            At the very center are cross-user and cross-project collaboration. Any ML content, such as entire ML projects or modular based 
            scripts (e.g. a new model) can be shared instantly and used by anyone. We call this "cross-project synergies". 
          </p>
          <p>
            We at MLReef believe in the democratization of Machine Learning. We believe
            that each individual stands at the center to empower the collective.
            On MLReef, all users within an organization should be able to participate in the
            ML value chain - with no boundaries and unlimited operational freedom.
          </p>

          <h2 className="mt-5">The company</h2>
          <p>
            MLReef was founded in 2019 in Austria, in the heart of Europe. Since our first day 
            we are soley devoted on developing the MLOps platform MLReef. MLReef is part of a 
            different consortia within academia and industry. Since its founding, MLReef has been 
            sponsored by NVIDIA, Amazon, Google, ESA BIC, Netidee, the Austrian government and 
            the European Union.
          </p>

          <div className="about-page-banner dark card mt-4">
            <div className="about-page-banner-container card-container banner">
              <div className="">
                <div className="">
                  Build AI and protect nature
                </div>
                <p>
                  We use revenue generated by cloud computing resources to support environmental projects. 
                  We believe that technology and nature can have a symbiotic relationship - especially when building
                  a company that uses reefs in their brand :) 
                </p>
              </div>
            </div>
          </div>
          <a name="team" />
          <br />
          <h2 className="mt-5">Meet the team</h2>
          <p>
            MLReef is built by the community and our core team members. MLReef is
            all remote, interdisciplinary, diverse and inclusive.
          </p>
        </div>
      </div>

      <TeamGallery />

      <section className="about-page-section advisors">
        <div className="">
          <div className="">
            <h2>Advisors and supporters</h2>
            <p>
              MLReef is supported by great advisors and supporters! Thanks to them we are able to move in the 
              right direction, get honest feedback and expand to new horizons.
            </p>
          </div>
        </div>
      </section>
      
      <AdvisorGallery />

      <section className="about-page-section contact">
        <div className="contact-title">Still have questions about MLReef?</div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`mailto:${getEmail(props)}`}
          className="btn btn-primary mt-2"
        >
          Get in touch
        </a>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query FetchEmail {
    site {
      siteMetadata {
        email
      }
    }
  }
`;

export default AboutPage;
