import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import './AdvisorGallery.scss';

const AdvisorGallery = props => {

  const { dataYaml: { advisors: { members } } } = useStaticQuery(
    graphql`
    query FetchAdvisorsMembers {
      dataYaml {
        advisors {
          members {
            avatar
            href
            location
            name
            advise
            role
          }
        }
      }
    }
    `,
  );
  // console.log(members);

  return (
    <div className="advisor-gallery">
      <div className="advisor-gallery-container">
        {members.map(member => (
          <div
            key={`advisors-card-${member.name}`}
            className="advisor-gallery-card card mb-4"
          >
            <div className="advisor-gallery-card-container card-container">
              <div className="advisor-gallery-card-avatar">
                <img className="avatar" src={member.avatar} alt={member.name} />
              </div>
              <div className="advisor-gallery-card-content">
                <div className="name mb-2">
                  {member.name}
                </div>
                <div className="role t-info mb-2">
                  {member.role}
                </div>
                <div className="advise mb-2">
                  {member.advise}
                </div>
                <footer className="location t-secondary">
                  {member.location}
                </footer>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdvisorGallery;
